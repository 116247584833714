import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

// 获取原型对象上的push函数
const originalPush = Router.prototype.push
// 修改原型对象中的push方法
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const baseRouters = [
  {
    path: '/',
    name: 'Home',
    component: () => import('@/views/index/index'),
    meta:{
        title:'满意度调查',
    }
  },
  {
      path: '/login/wechat',
      name: 'LoginWechat',
      component: () => import('@/views/login/wechat'),
      meta:{
          title:'企业微信登录',
      }
  },
  {
      path: '/login/login',
      name: 'LoginLogin',
      component: () => import('@/views/login/login'),
      meta:{
          title:'登录',
      }
  },
  {
      path: '/contacts/:id',
      name: 'Contacts',
      component: () => import('@/views/contacts/index'),
      meta:{
          title:'联系人信息',
      }
  },
  {
      path: '/questionnaire/:uuid',
      name: 'Questionnaire',
      component: () => import('@/views/questionnaire/index'),
      meta:{
          title:'满意度调查问卷',
      }
  },
  {
      path: '/questionnaire/view/:uuid',
      name: 'QuestionnaireView',
      component: () => import('@/views/questionnaire/view'),
      meta:{
          title:'满意度调查问卷-预览',
      }
  },
  {
      path: '/contacts/special/:id',
      name: 'ContactsSpecial',
      component: () => import('@/views/contacts/special'),
      meta:{
          title:'满意度调查问卷-特殊情况申请',
      }
  },
  {
      path: '/contacts/progress/:id',
      name: 'ContactsProgress',
      component: () => import('@/views/contacts/progress'),
      meta:{
          title:'满意度调查问卷-特殊情况申请进度',
      }
  },
  {
      path: '/questionnaire/sms/:uuid',
      name: 'QuestionnaireSms',
      component: () => import('@/views/questionnaire/sms'),
      meta:{
          title:'满意度调查问卷',
      }
  }
]

// 需要通过后台数据来生成的组件

const createRouter = () => new Router({
  mode: 'history',
  routes: baseRouters
})

const router = createRouter()

export default router
