<template>
    <div id="app" class="body-width">
        <router-view :key="$route.fullPath" v-if="isRouterAlive"/>
    </div>
</template>
<script>
export default {
    provide(){//注入依赖
        return{
            reload:this.reload,
        }
    },
    data() {
        return {
            isRouterAlive:true,
            // now_path:'/',
        };
    },
    created() {

    },
    methods:{
        reload (){
            this.isRouterAlive = false;
            this.$nextTick(function(){
                this.isRouterAlive = true;
            })
        },
    },
    watch: {
        // $route:{
        //     handler: function(route) {
        //         let _this = this;
        //         let pts = route.path.split('/');
        //         if (pts.length > 2) {
        //             _this.now_path = '/'+pts[1];
        //         } else {
        //             _this.now_path = route.path;
        //         }
        //     }
        // }
    },
    mounted() {
        //禁止鼠标右键菜单
        document.oncontextmenu = function(e) {
            return false;
        };
    }
}
</script>
